import { Box, Container, Heading, SimpleGrid } from "@chakra-ui/react";
// import Image from "next/image";
import Link from "next/link";
import useSite from "../../hooks/use-site";

const ArchiveMembershipPlans = () => {
  const {
    page: { nodes },
  } = useSite();
  return (
    <Container my={8}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
        {nodes.map(({ title, uri, logo }, i) => (
          <Link href={uri} passHref key={i}>
            <Box
              as="a"
              display="block"
              p={4}
              border="1px"
              borderColor="gray.500"
              textAlign="center"
              textDecor="none"
            >
              <Box
                width="235px"
                height="35px"
                mx="auto"
                position="relative"
                sx={{
                  img: {
                    display: "block",
                    mx: "auto",
                    maxH: "100%",
                  },
                }}
              >
                <img
                  src={logo.url.replace(
                    process.env.NEXT_PUBLIC_FRONTEND_URL,
                    process.env.NEXT_PUBLIC_WORDPRESS_URL
                  )}
                  width={`${logo.width}px`}
                  height={`${logo.height}px`}
                  alt={logo.alt}
                />
              </Box>
              <Heading as={"h2"} mt="4" fontSize="md">
                {title}
              </Heading>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default ArchiveMembershipPlans;
