import { months } from "../data/months";
import { wordpressUrl } from "../utilities/variables";

// Updates any backend URL's with NextJS app frontend
export function urlUpdate(url) {
  if (url === wordpressUrl) {
    return "/";
  } else {
    return url.replace(wordpressUrl, "");
  }
}

export function convertAcfBlockName(str) {
  let component = "";

  // Build Component Name
  str
    .split("-")
    .map((n) => (component += n.charAt(0).toUpperCase() + n.slice(1)));

  return component;
}

export function searchObjectRecursively(arr, key, value, search) {
  let result = [];

  arr.forEach((obj) => {
    if (obj[key] === value) {
      result.push(obj);
    } else if (obj[search]) {
      result = result.concat(
        searchObjectRecursively(obj[search], key, value, search)
      );
    }
  });
  return result;
}

export function formatDate(postDate) {
  return `${
    months[postDate.getMonth()]
  } ${postDate.getDay()}, ${postDate.getFullYear()}`;
}
