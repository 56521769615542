import { gql } from "@apollo/client";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  FeatureImageFragment,
  NavigationMenuFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";
import SiteWrapper from "../components/SiteWrapper";
import PageBanner from "../components/PageBanner";
import parse from "html-react-parser";
import options from "../support/parse-settings";

export default function Component(props) {
  const { isFrontPage } = props.data.page;

  const siteProps = Object.assign({}, props.data, {
    acfData: JSON.parse(props.data.page.blockData),
    postType: "page",
  });

  return (
    <SiteWrapper siteData={siteProps}>
      {!isFrontPage && <PageBanner />}
      {props.data.page.content && parse(props.data.page.content, options)}
    </SiteWrapper>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${SiteSettingsFragment}
  ${NavigationMenuFragment}
  ${FeatureImageFragment}
  ${SingleFormFragment}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $formId: ID = "1"
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      blockData
      isFrontPage
      content
      ...FeaturedImageFragment
      pageSettings {
        hideFooterForm
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        breadcrumbs {
          url
          text
        }
        schema {
          pageType
          articleType
          raw
        }
      }
      saswpCustomSchema {
        json_ld
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $formId, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
