import { gql } from "@apollo/client";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  FeatureImageFragment,
  NavigationMenuFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";
import SiteWrapper from "../components/SiteWrapper";
import MembershipPlansSingle from "../components/MembershipPlans";

export default function Component(props) {
  // Change membershiplans key to page

  const pageData = { ...props.data };

  pageData.page = props.data.membershipplan;

  // Assign Plan Data to Context
  if (pageData.page?.planData) {
    const planData = JSON.parse(pageData.page.planData);
    pageData.planData = planData;
  }
  pageData.postType = "page";
  return (
    <SiteWrapper siteData={pageData}>
      <MembershipPlansSingle />
    </SiteWrapper>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${FeatureImageFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $id: ID = "1"
  ) {
    membershipplan(
      id: $databaseId
      idType: DATABASE_ID
      asPreview: $asPreview
    ) {
      title
      ...FeaturedImageFragment
      planData
      seo {
        canonical
        title
        metaDesc
        focuskw
        schema {
          pageType
          articleType
          raw
        }
        breadcrumbs {
          url
          text
        }
      }
      saswpCustomSchema {
        json_ld
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $id, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
