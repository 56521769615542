import {
  Box,
  Container,
  Heading,
  HStack,
  VStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import useSite from "../../hooks/use-site";
import { wpStyles } from "../../theme/wp-styles";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { urlUpdate } from "../../support/functions";

const PageBanner = () => {
  const site = useSite();
  const title = site[site.postType]?.title;
  const breadcrumbs = site[site.postType]?.seo?.breadcrumbs;

  console.log("Breadcrumbs", breadcrumbs);
  return (
    <Box
      py="6"
      borderTop="1px"
      borderBottom="1px"
      borderColor={wpStyles.colors.border}
      mb={12}
    >
      <Container textAlign="center">
        <VStack align="stretch" gap={4}>
          <Heading
            fontSize={{
              base: wpStyles.fontSize.lg,
              md: wpStyles.fontSize.xl,
              lg: wpStyles.fontSize.colossal,
            }}
            as="h1"
            mb="0"
          >
            {title}
          </Heading>
          {breadcrumbs && breadcrumbs.length > 0 ? (
            <HStack
              justify="center"
              divider={
                <Box border="none">
                  <Icon as={ChevronRightIcon} />
                </Box>
              }
              fontSize="sm"
              sx={{
                a: {
                  textDecoration: "none!important",
                },
              }}
            >
              {breadcrumbs.map((breadcrumb, index) => (
                <Link
                  key={index}
                  href={urlUpdate(breadcrumb.url)}
                  textDecoration="none"
                >
                  {breadcrumb.text}
                </Link>
              ))}
            </HStack>
          ) : (
            <></>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default PageBanner;
