import { gql } from "@apollo/client";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  NavigationMenuFragment,
  FeatureImageFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";
import SiteWrapper from "../components/SiteWrapper";
import PageBanner from "../components/PageBanner";
import Archive from "../components/Archive";
import parse from "html-react-parser";
import options from "../support/parse-settings";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  // Assigning Additional Props to Site Hook

  // First - assign fullProps with all the data from Apollo
  let fullProps = { ...props.data };

  //
  let postsProps = { ...props.data.posts };
  let pageProps = { ...props.data.page };

  if (pageProps?.seo) {
    postsProps.seo = pageProps.seo;
  }

  postsProps.title = "Resources";

  fullProps.posts = postsProps;

  fullProps.page = pageProps;

  const siteProps = Object.assign({}, fullProps, {
    postType: "posts",
  });

  return (
    <SiteWrapper siteData={siteProps}>
      <PageBanner />
      {props.data.page.content && parse(props.data.page.content, options)}
      <Archive />
    </SiteWrapper>
  );
}

Component.variables = ({}, ctx) => {
  return {
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${FeatureImageFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetArchivePage($headerLocation: MenuLocationEnum, $formId: ID = "1") {
    page(id: 3766, idType: DATABASE_ID, asPreview: false) {
      title
      blockData
      isFrontPage
      content
      ...FeaturedImageFragment
      pageSettings {
        hideFooterForm
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        schema {
          pageType
          articleType
          raw
        }
        breadcrumbs {
          url
          text
        }
      }
    }
    posts {
      nodes {
        date
        excerpt
        id
        link
        title
        featuredImage {
          node {
            id
            sourceUrl
            altText
            mediaDetails {
              width
              height
            }
            sizes(size: MEDIUM)
          }
        }
        categories {
          nodes {
            categoryId
            name
          }
        }
        tags {
          nodes {
            name
            link
            id
          }
        }
      }
    }

    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $formId, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
